@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.input {
  @include registerInput;
}

.fieldWithError {
  border-color: $negative;
}

.singleField {
  &:not(:last-child) {
    @include ltr {
      margin-right: $spacing-md;
    }
    @include rtl {
      margin-left: $spacing-md;
    }
  }
}

.fieldsContainer {
  display: flex;
}

.year {
  min-width: 60px;
}

.month {
  flex-grow: 1;
  min-width: 150px;
}
