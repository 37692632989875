@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.steps {
  display: none;
  position: absolute;
  top: -16px;
  background: $white;
  padding: 0 25px;
  margin: 0;
  list-style: none;

  @include ltr {
    right: 10%;
  }

  @include rtl {
    left: 10%;
  }

  @include tabletScreensUp {
    display: block;
  }
}

.isStatic {
  position:static;
  margin-bottom: 20px;
}

.step {
  display: inline-flex;
  align-items: center;
  font-size: 14px;

  &:not(:last-child) {
    @include ltr {
      margin-right: 47px;
    }
    @include rtl {
      margin-left: 47px;
    }
  }
}

.stepNumber {
  width: 32px;
  height: 32px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: $gray-98;
  border-radius: 50%;
  line-height: 0;
  color: $black;
  font-size: 14px;
  position: relative;

  span {
    transform: translateY(-2px);
  }

  @include ltr {
    margin-right: 8px;
  }
  @include rtl {
    margin-left: 8px;
  }
}

.activeStep {
  background: $color-primary-light-1;
}
