@import 'styles/variables.scss';
@import 'styles/mixins.scss';

$border-width: 2px;

.tabs {
  position: relative;
  z-index: 75;
  border-radius: 50px;
  border: $border-width solid hsl(210, 3%, 75%);
  display: flex;
  align-items: center;
  width: 250px;
  margin: 0 auto;
  background: $white;
}

.tab {
  background: transparent;
  color: $text-color-primary;
  padding: 7px 20px;
  font-weight: $body-font-weight;
  font-size: 12px;

  @include tabletScreensUp {
    font-weight: $heading-font-weight;
  }
}
.activeTab {
  color: $white;
}

.focused {
  background: $color-primary-2;
  border-radius: 50px;
  position: absolute;
  top: -$border-width;
  height: calc(100% + $border-width * 2);
  z-index: -1;
  transition: all 0.2s ease-in-out;
}
