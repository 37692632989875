@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.boothHeading {
  display: flex;
  align-items: center;

  h3 {
    font-style: normal;
    font-weight: $body-font-weight;
    font-size: $body-font-size;
    line-height: 22px;
    color: $black;
  }
}

.fieldset {
  margin-bottom: 30px;
}
