@import 'styles/mixins.scss';

.numberIcon {
  width: 69px;
  height: auto;

  @include tabletScreensUp {
    width: 80px;
  }
}

.stepIcon {
  height: 62px;
  width: auto;

  @include tabletScreensUp {
    height: 134px;
  }
}

.one {
  fill: hsl(0, 0%, 100%);

  @include tabletScreensUp {
    fill: hsl(216, 46%, 88%);
  }
}
