@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.container {
  max-width: $box-section-max-width;
  margin: 0 auto;
  border-radius: $box-section-border-radius;
  padding: $box-section-padding;
  background: $white;
  position: relative;

  @include tabletScreensUp {
    margin-top: 85px;
    padding: $box-section-padding-desktop;
    border: 1px solid $dimmed-blue-1;
  }
}

.heading {
  font-style: normal;
  margin-top: 5px;
  color: $black;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  text-align: center;

  @include tabletScreensUp {
    color: $dimmed-blue-3;
    font-weight: $heading-font-weight;
    font-size: 30px;
    line-height: 44px;
    text-align: initial;
  }
}

.requiredInfo {
  display: none;
  color: $negative;
  font-size: 14px;
  margin-bottom: $spacing-md;

  @include tabletScreensUp {
    display: block;
  }
}

.sectionHeader {
  margin-top: 60px;

  @include tabletScreensUp {
    margin-top: 0;
  }
}

.appsStep {
  &:not(:last-child) {
    @include ltr {
      margin-right: 25px;
    }
    @include rtl {
      margin-left: 25px;
    }
  }
}
