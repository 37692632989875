@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.buttonsContainer {
  margin-top: $spacing-lg;
  display: flex;
  justify-content: space-between;
  flex-direction: column-reverse;

  @media (min-width: 500px) {
    flex-direction: row;
  }

  button {
    &:not(:last-child) {
      @include ltr {
        margin-right: 10px;
      }

      @include rtl {
        margin-left: 10px;
      }
    }

    &:not(:first-child) {
      margin-bottom: 10px;

      @media (min-width: 500px) {
        margin-bottom: 0;
      }
    }
  }
}


.list {
  list-style-type: circle;
}
.content {
  margin: 0;
  padding: 0;
}

.icon {
  margin: 0 auto;
  width: 112px;
  height: auto;
}
