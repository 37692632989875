@import 'styles/variables.scss';

.footer {
  background: $light-blue;
  padding: 80px 20px 40px 20px;

  @media (min-width: 840px) {
    padding: 80px 24px 93px;
  }

  @media (min-width: 768px) {
    margin-top: 120px;
  }
}

.footerWrapper {
  max-width: $max-footer-width;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}

.firstBar {
  width: 100%;
  padding-block-end: 34px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 37px;

  @media (min-width: 840px) {
    padding-block-end: 39px;
    flex-direction: row;
    border-block-end: 1px solid $sky;
  }
}

.logosWrapper {
  display: flex;
  align-items: center;
  height: 100%;
  gap: 19px;
}

.divider {
  height: 40px;
  width: 1px;
  background: $sky;
}

.linksWrapper {
  color: $dark-grey;
  font-size: 16px;
  display: flex;
  gap: 36px;
  justify-content: space-between;
  max-width: 50%;
  min-width: 250px;
  flex-direction: column;
  text-align: center;
  align-items: center;
  border-block-end: 1px solid $sky;
  padding-block-end: 60px;

  @media (min-width: 480px) {
    gap: 59px;
    justify-content: flex-start;
    width: unset;
  }

  @media (min-width: 840px) {
    gap: 36px;
    flex-direction: row;
    justify-content: flex-start;
    width: unset;
    border-block-end: none;
    padding-block-end: 0px;
  }
  }

.link {
  text-transform: uppercase;
  position: relative;

  &:not(.activeLink):hover {
    &::after {
      width: 100%;
    }
  }

  &::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 0;
    height: 2px;
    background-color: $light-purple;
    transition: width 300ms;
  }
}

.activeLink {
  text-decoration: underline;
  text-decoration-color: $light-purple;
  text-decoration-thickness: 2px;
  text-underline-offset: 16px;
}

.secondBar {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  @media (min-width: 840px) {
    padding-block-start: 18px;
    flex-direction: row;
  }
}

.initiative {
  padding-block-start: 2px;
  gap: 18px;
  order: 3;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: $very-dark-blue;
  font-size: 14px;
  flex: 1;
  margin-top: 30px;

  @media (max-width: 839px) {
    flex-direction: column;
  }

  @media (min-width: 840px) {
    order: 1;
    flex-direction: row;
    margin-top: 0;
  }
}

.links {
  order: 1;
  width: 100%;
  color: $very-dark-blue;
  font-size: 14px;
  gap: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 840px) {
    margin-inline-end: 52px;
    width: unset;
    order: 2;
    margin-block-end: 0;
    flex-direction: row;
    align-items: initial;
  }
}

.href {
  padding: 2px 10px;
  border-radius: 5px;
  transition: all 0.2s linear;
  color: inherit;
  background: transparent;

  &:hover {
    background: rgba($light-purple, 0.16);
    color: $light-purple;
  }
}

.socials {
  order: 2;
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: center;
  margin-top: 30px;

  @media (min-width: 840px) {
    margin-block-end: 4px;
    margin-block-start: 0;
  }
}

.socialLink {
  width: 24px;
  height: 24px;
  background: $very-dark-blue;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  transition: background 0.15s linear;

  &:hover {
    background: $light-purple;
  }
}

.linkWithLogo {
  display: flex;
  align-items: center;
}
