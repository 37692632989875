@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.container {
  width: $container-max-width;
  margin: 0 auto;
  position: relative;
}

.path {
  position: absolute;
  display: none;

  @include ltr {
    transform: scale(-1, 1);
  }

  @media (min-width: 1200px) {
    display: block;
  }
}

.path1to3 {
  top: -120px;

  @include rtl {
    right: 14%;
  }

  @include ltr {
    left: 14%;
  }

  &.pathTojjarApps {
    top: -170px;

    @include rtl {
      right: 10%;
    }

    @include ltr {
      left: 10%;
    }
  }
}

.path3to5 {
  top: -50px;

  @include rtl {
    left: -8%;

    &.pathTojjarApps {
      left: 7%;
    }
  }

  @include ltr {
    right: -8%;

    &.pathTojjarApps {
      right: 7%;
    }
  }
}
