@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.container {
  max-width: $box-section-max-width;
  margin: 0 auto;
  padding: $box-section-padding;
  position: relative;
  background: $color-secondary;

  @include tabletScreensUp {
    border-radius: $box-section-border-radius;
    margin-top: 120px;
    padding: $box-section-padding-desktop;
  }
}
.heading {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: center;

  @include tabletScreensUp {
    flex-direction: row-reverse;

    &.headingReversed {
      flex-direction: row;
    }

    @include rtl {
      flex-direction: row;
    }
  }

  h2 {
    color: $text-color-primary;
    font-size: 26px;
    font-weight: $body-font-weight;
  }
}

.tabs {
  margin: 0 0 25px 0;

  @include tabletScreensUp {
    margin: 0;
  }
}

.questionList {
  margin: 25px 0;
}
