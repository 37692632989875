@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.root {
  text-align: center;
  margin-bottom: $spacing-lg;

  @include tabletScreensUp {
    margin-bottom: $spacing-md;
  }
}

.heading {
  font-weight: normal;
  font-size: 26px;
  margin: 10px 0;

  color: $text-color-primary;

  @include tabletScreensUp {
    font-size: 40px;
  }
}

.subHeading {
  font-size: 15px;
  line-height: 22px;
  font-style: normal;
  font-weight: normal;
  color: $text-color-secondary;
  margin: 10px 0;
}
