@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 45px 0;

  @include tabletScreensUp {
    flex-direction: row;
  }
}

.stepIndicatorContainer {
  flex-grow: 1;
  display: none;
  justify-content: center;
  align-items: center;

  @include tabletScreensUp {
    display: flex;
  }
}

.dot {
  @include stepDot;
}

.buttonContainer {
  display: flex;
  min-width: 100%;

  @include tabletScreensUp {
    min-width: calc(100% / 3);
  }

  &:first-child {
    justify-content: flex-start;
  }
  &:last-child {
    justify-content: flex-end;
  }
}

.formButton {
  padding: 14px 53px;
  max-width: 500px;
  margin: 0 auto;

  &:first-child {
    margin-bottom: 50px;
  }

  @include tabletScreensUp {
    margin: 0;

    &:first-child {
      margin-bottom: 0;
    }
  }
}

@mixin arrow {
  content: '';
  border-style: solid;
  border-width: 2px 2px 0 0;
  display: block;
  width: 8px;
  height: 8px;
}

.buttonWithArrow {
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrowLeft {
  &:before {
    @include arrow;

    @include ltr {
      margin-right: 10px;
      transform: rotate(-135deg);
    }
    @include rtl {
      margin-left: 10px;
      transform: rotate(45deg);
    }
  }

  .arrowLight {
    &:before {
      border-color: $white;
    }
  }

  .arrowDark {
    &:before {
      border-color: $dimmed-blue-3;
    }
  }
}

.arrowRight {
  &:after {
    @include arrow;

    @include ltr {
      margin-left: 10px;
      transform: rotate(45deg);
    }
    @include rtl {
      margin-right: 10px;
      transform: rotate(-135deg);
    }
  }

  .arrowLight {
    &:after {
      border-color: $white;
    }
  }

  .arrowDark {
    &:after {
      border-color: $dimmed-blue-3;
    }
  }
}
