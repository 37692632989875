@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.root {
  margin: 20px 0 140px 0;

  @include tabletScreensUp {
    margin: 70px 0 20px 0;
  }
}

.slide {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  z-index: 80;

  img {
    border-radius: 15px;
  }
}

.dots {
  display: none;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  min-height: 25px;

  @media (min-width: 1200px) {
    display: flex;
  }
}

.dot {
  @include stepDot;
}

.logos {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;
  margin: -12px 10px -45px;
  .logo {
    width: 60px;
    height: 60px;
  }
  @media (min-width: 768px) {
    gap: 100px;
    margin: 24px 10px 0;
    .logo {
      width: 80px;
      height: 80px;
    }
  }
  @media (min-width: 1200px) {
    gap: 114px;
    margin: 26px 10px 31px;
    .logo {
      width: 100px;
      height: 100px;
    }
  }
}


