@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.root {
  max-width: 1135px;
  padding: $spacing-lg 0;
  margin: 80px $spacing-md;
  display: flex;
  flex-direction: column-reverse;

  @include tabletScreensUp {
    margin: 0px 30px 40px 30px;
    flex-direction: row;
  }

  @media (min-width: 1200px) {
    margin: 0px auto 40px auto;
  }
}

.image {
  height: 182px;
  width: 100%;
  background-image: url('../../assets/images/booth.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  @include ltr {
    transform: scale(-1, 1);
  }

  @include tabletScreensUp {
    height: 304px;
    width: 50%;
  }
}

.content {
  width: 100%;

  @include tabletScreensUp {
    width: 50%;

    @include ltr {
      margin-right: $spacing-lg;
    }
    @include rtl {
      margin-left: $spacing-lg;
    }
  }

  p {
    color: $text-color-secondary;
    font-size: $body-font-size;
    margin-bottom: 30px;
  }
}

.heading {
  margin: 0 0 10px 0;
  font-size: 26px;
  line-height: 38px;
  font-weight: $body-font-weight;

  @include tabletScreensUp {
    font-size: $spacing-lg;
    line-height: 59px;
    font-weight: $heading-font-weight;
  }
}

.subHeading {
  color: $dimmed-blue-2;
  font-weight: $body-font-weight;
  font-size: 16px;
  display: block;
  margin-top: 30px;

  @include tabletScreensUp {
    margin-top: 0;
    color: $text-color-primary;
    font-size: 28px;
  }
}

.button {
  max-width: 500px;
  display: block;
  margin: 80px auto 0 auto;

  @include tabletScreensUp {
    display: inline-block;
    margin: 0;
  }
}
