@import 'styles/variables.scss';
@import 'styles/mixins.scss';

$baseColor: $text-color-secondary;

@mixin icon($base64Icon) {
  &::before {
    content: url($base64Icon);
    display: block;
    width: 20px;
    height: 20px;

    @include ltr {
      margin-right: 6px;
    }
    @include rtl {
      margin-left: 6px;
    }
  }
}

.root {
  margin-bottom: $spacing-md;
  font-size: 14px;
}

.title {
  margin-bottom: $spacing-md;
  display: flex;
  align-items: center;
}

.clicable {
  cursor: pointer;
}

.content {
  color: $baseColor;
}

.info {
  color: $baseColor;

  @include icon(
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAJhSURBVHgBjVRdctpADJbW8E5PUPcESW6QngB6g+YtQ8G1cwHgAmWLKZM30hPUOUHaE9Q3qHMDng22qs8/xAHDRDMLa2lXK+n7JKYW8X2/l2U0EKI+M1+qyoVev2MSSXKix1VoH9ru8qFiNPIHbHit2x6dlyQTmR06Ns2P8difq7NflbM/uUiwNfIhXMwZKzNypRHeaBQJonaY1xrApDXC0ehuwkamut2Q8CwMv1k6I/q4T8yT4nERG4Y22DtU42c1Is1NtpOPq5WNoddauruM1lUdeyIS7bYU3N/bBPbh0L90OvxU2HL5tFzaqHDoecE/QeGFgzoyOMty/kvHtdxsU7mqnVaRzqF3tDwG0UmJYtJMM8sJ+56++FMPvsNh7KHrdmldn9NUrcL/G/pUmWE06UHhQBF7HQj38ZumMrXWbnQlxohfmooS7CUnecS/gjToCPH7Im+H4uYhoErH0kqlrkORZoS0L4zeKl5bWRvTGQHZ87yglCYl35s2RF9t3Q69QQCQOntCrUWdhQs7PXUWxH7G5vbWd08d2mW8hjONbLZcWP/QPvT9Iku0pjqUIlWnW4LTJsx0jf80pYc2u5OVZdPyPXc0xkhh6qtnoNraHScAajzIE40OTImMQ+oQ7cZ8/QUkbZGxFwhWmw13ah5jUBhwTNvmBkajL6Gd6I2Cs6bs5z2PX4aDF1j9+IpodcrMfqADzggiq5z1ANaiQv5VbTzvbqq0qMdRMe8oo7geFmACwONy8F5DBxo1kT8qNnq7KrJL56UoFSZMU3kSvWqkgUqurotKDc7GZCQCmKj/4b3/He4fAhRhww0AAAAASUVORK5CYII='
  );
}

.warning {
  color: $warning;

  @include icon(
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGdSURBVHgBrVWNVcJADP6uLsAGlgnADXAC6gIIG+AEyATIBIAOYJkA3aAbWCaQBcr5JQ2/7wq8Pr/3QtNr8l1ySQ6HAPwMDdwhodqltClx+QEZHHIUWLoB5iFfFyBLSDaj2sAleBJvMT4njk5s5piQ7NPIvikvjKbpenAi1B+4NqDkfItlY7/AKLzhB0b+HZ7ySxniCsTGbMVnguOUmWbf0twwikemkeEG0K9Nv5VmVOCJfmlJuMCPpsAUmdpbIBqvu/cCZ15mM7FgmpFGV5LlIbJrUB+HL42SnRGxLIl9G6MuPJb2TKTK9/pS3HZuQRRI9enQivjTVn1Qn5C+ualxhH+GEK5FYXHiKqN9Y1dA20cNkUU6n4K7fXHqoCTcYi1nmNpit8rapsFX0kU2fuSKrEIbSoehXx25wGbDoz6e70YvsUuh/ujx0hBCrbLMIBOaouz21S2RamSHOR4LmXKdGC3wypXyPOy+o5btItZOOFy8HbObuudDAKFh7/MhpDEuQ45noNkdwV1IqS+zaQfesuW1/Q2kUkySbc79/gDKrKDRha0zpAAAAABJRU5ErkJggg=='
  );
}
