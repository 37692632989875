@import 'styles/variables.scss';

.select {
  :global(.select-field__control) {
    background: #f9f9f9;
    border-color: transparent;
    cursor: pointer;

    &:hover {
      border-color: #dadada;
    }
  }

  :global(.select-field__control--is-focused) {
    box-shadow: 0 0 0 1px #dadada;
  }

  :global(.select-field__value-container) {
    padding: 1px 8px;
  }

  :global(.select-field__indicator) {
    color: $black;

    &:hover {
      color: $black;
    }
  }
  :global(.select-field__indicator-separator) {
    display: none;
  }
  :global(.select-field__menu) {
    z-index: 140;
  }
  :global(.select-field__option) {
    &:hover {
      background: $color-primary-light-1;
    }
  }
  :global(.select-field__option--is-focused) {
    background: $color-primary-light-1;
  }
  :global(.select-field__option--is-selected) {
    background: $color-primary-2;
  }
}

.withError {
  :global(.select-field__control) {
    border-color: $negative;

    &:hover {
      border-color: $negative;
    }
  }
}
