@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.container {
  margin-bottom: 20px;
}

.label {
  margin-bottom: 8px;
  display: block;
  font-weight: $body-font-weight;
  color: $black;
  font-size: 14px;
}

.required {
  &::after {
    content: '*';
    color: $negative;
  }
}

.error {
  margin-top: 8px;
  color: $negative;
  font-size: $body-font-size;
  font-weight: $body-font-weight;
}

.withError {
  [class='field'] {
    border-color: $negative;

    &:focus-within {
      box-shadow: none;
    }
  }
}
