@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.row {
  display: flex;
  flex-direction: column;

  @include tabletScreensUp {
    flex-direction: row;
  }
}

.col50 {
  width: 100%;

  @include tabletScreensUp {
    width: 50%;

    &:not(:last-child) {
      @include ltr {
        margin-right: 20px;
      }

      @include rtl {
        margin-left: 20px;
      }
    }
  }
}
