@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.question {
  direction: rtl;
  border-radius: 3px;
  background: $white;
  padding: 16px;
  border: 1px solid $color-secondary-1;

  &:not(:last-child) {
    margin-bottom: 24px;
  }
}

.questionHeading {
  color: $text-color-primary;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;

  &::after {
    content: '';
    border-style: solid;
    border-width: 3px 3px 0 0;
    border-radius: 2px;
    display: inline-block;
    height: 10px;
    width: 10px;
    transition: all 0.2s linear;
  }
}

.questionCollapsed {
  &::after {
    transform: rotate(135deg);
  }
}

.questionExpanded {
  &::after {
    transform: rotate(-45deg);
  }
}

.questionContent {
  border-top: 1px solid hsla(217, 23%, 74%, 0.49);
  margin-top: 15px;
  padding: 15px 0;
  font-size: $body-font-size;
  font-weight: $body-font-weight;
  color: $dimmed-blue-2;
}
