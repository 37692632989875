@import 'styles/mixins.scss';

.input {
  @include registerInput;
  direction: ltr;

  @include rtl {
    text-align: right;
  }
}
