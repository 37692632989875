@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.steps {
  margin-top: 90px;

  @include tabletScreensUp {
    margin-top: 45px;
    display: flex;
    flex-wrap: nowrap;
    padding: $spacing-md;
    overflow-x: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.step {
  display: grid;
  grid-template-areas: 'number heading icon' 'number subheading icon';
  grid-template-columns: min-content 5fr minmax(80px, 1fr);
  column-gap: 30px;
  position: relative;
  flex: 0 0 auto;

  &:not(:nth-child(3)) {
    .stepNumber {
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: calc(100% + 15px);
        right: 50%;
        width: 3px;
        height: 72px;
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAABICAYAAADcdSQuAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAA8SURBVHgB7Y6xEQAQEATvjKrohCa+A1T+jJEJCCRmfqON7pYxi2LgsNjFq6LhIQxJ6lwmUQ7vN2KFvxd2yhcUcZUxPOYAAAAASUVORK5CYII=');
        background-repeat: repeat-y;
        background-position: center center;

        @include tabletScreensUp {
          content: none;
        }
      }
    }

    margin-bottom: 100px;

    @include tabletScreensUp {
      margin-bottom: 0;
    }
  }

  @include tabletScreensUp {
    grid-template-areas: 'number' 'icon' 'heading' 'subheading';
    grid-template-columns: 1fr;
    column-gap: 0;
    background: $color-secondary;
    border-radius: 20px;
    padding: 70px 20px;
    width: 320px;
    text-align: center;

    &:not(:last-child) {
      @include ltr {
        margin-right: calc($spacing-md * 2);
      }
      @include rtl {
        margin-left: calc($spacing-md * 2);
      }
    }

    &:not(:nth-child(3)) {
      .stepIcon {
        &::after {
          content: '';
          display: block;
          position: absolute;

          width: 90px;
          height: 68px;
          background-repeat: no-repeat;
          background-position: center center;
        }
      }
    }

    &:first-child {
      .stepIcon {
        &::after {
          background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHIAAAAZCAYAAADt7nrkAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALNSURBVHgB7ZlPbtNAFMY/O2kJYpMlG0Qq1qiWEFKgoJoTYBZIUCoRbmBu0C5Z4RvUWYCQWJCeoI4EKBILDBygBg5AWBGaP+Z7zrRKWiAlieI4zE+dznPtRs58fm++GRuYE67eda18HiXDQFGOY/ZG3I8HWV6GF/heE5oh8pgTKJDL7uHhsXH0a5hWCz67vwppV9xiu433DKM45rUxPscm4y7CbhfNdy+9EAvGVIW0HbfYKsAycmwxVpldFgdy9+1zb2vU//L6qAfUeX2UHBuJWCcEKxQwMhsPDpLMLjGUPnkgkmcixx+2tQfyzCA87b1lgYmEPBTONOAYJlbbgMVx6pdDlU1KkJG8nuKAUpywfM9dSUo176cTs48TYS0l8EWJ2epYEMYWUpWvb7mTpz70eghhIuTgBSyZEVKg8cKL2EW/Oyf33unAWlrCqUrstfuuw+ujeS7JBiaAJWqP5Uk+IzRN1JgB4SIakbUNd5/fsqTKfiAl+cwPBEFtfr7rREL+D6jK4zFcZysdOy2iVlmBAlUBUmNISJnzfp5DhSWxyXnGh2aI6xuuxWWRzfFxONeuHzvtvXnmPUZKJELSGNjKsIj9F7PS5Pyxotdrf0Yyle7YYSii3mZmPkrz4U/MDi35Kyi3yRuq52K96B6FGh9fmoiKlEkykqbFjfv23BfrDk3m0GZnhtzcdJ1uD08ZBjRI29M0SCY0M4Mi2rIhwVbhdLZP87RDf1LCFNAZOWNubLo2p7EKBvaVeewzQ6vM0ABjooVMCRqkEtenWxgQlATc1N8eR9AcNKkQhY3m10+N2qUr5Soz8jug9oI52fHvu/hHdEbOCZKh3M+tdPLwG366u0QajUajSdBmJ4PIa7ULl8tFmqKjF+Pa7GQMtQ7dk1jej3L9eUt2iLSQGUSJuQP1ftTs4Y4urRnky8dGpNafZ2PgfHcZT6DJNrL+lP4XCKUX1viI4CEAAAAASUVORK5CYII=');
          top: 50%;

          @include rtl {
            left: -30%;
          }
          @include ltr {
            right: -30%;
          }
        }
      }
    }
    &:nth-child(2) {
      .stepIcon {
        &::after {
          background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHIAAAASCAYAAACHKYonAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAKUSURBVHgB7ZjNbtNAFIXP5KdIbMgWCYTZ8xN2pYBwnwCzqyBS8wb1GxCeAPMGQUJQiQXJE9RIFRixMSqLLo2oxDYskEISx5wbDcgNoDaukd1oPmnkmUmUWHPmzj13AMNSUF174LYvXl19zfbty14QwnBqsDdc6/y1m5sHe8F7deuhuyNz8kGSIOLj8dsXXheG0mI7bmN0FltKoSNjFWNdSUeikpOP2LVkHNdxOeh6EQylQ2v1hN2GjBl8T1dW0FHzX6pUYO0+9zowlJLbLdemeDtsb6hVh1r5MJxOGHBN5IWc0zCUhioyIG5pfAZf6XStC1dW1cGnYB+GEyPBEe0HQ2SghgxMajRFCd2SgiTeNp3vgGd2j63/7qXXg+HYrG64NnPdXa6jOwak/FtHBhQyYrddazSCLS/A4fXURyKqz2d/OoUfbBv3Ow/FsyjeJtfOhi79BDEwdKCO3/UGWJDMQqYRUX+M4VSANlKiSn2zu21claANyr158ajAIJniGYXtncSB5iJkml+Ryq7DiwUHhhlrLbejklmtPhNvGqNfraJbqyHMEoHz5C7kosx2aoI2U64/mSD68MpbymtCqf+YahyJvLzES1MGITv6VilNqK8LP/IZlklgcZbf67DqdcgGbHIFL/HkuY+CKUVEJswbPHbEOFk4bJx+U0S+vdNynThmmlA4x3eTHGdBX42l4Wa7QTEL3WiZyo880QsgzZMxc2yDEdiMudsprsWpJhexMcEsQo+EpVCiuwPd/kDKJP6ve8RPIZ5yc1WwdWiS+Y2R+JnHZIgKwqpCyOiMUDCFCzmPzh2+bgshtj41bOAv0aM51hWX5DNuqIgbSt4pZGkQ5Z3b8qLwo/V/IZE9HP5TSCxbffsTRGL4B3Jpz3IAAAAASUVORK5CYII=');
          top: 40%;
          z-index: 10;

          @include rtl {
            left: -30%;
          }
          @include ltr {
            right: -30%;
          }
        }
      }
    }
  }
}

.stepHeading {
  grid-area: heading;
  font-weight: $heading-font-weight;
  font-size: 20px;
}

.stepSubHeading {
  grid-area: subheading;
  font-weight: $body-font-weight;
  font-size: $body-font-size;
  color: $text-color-secondary;
}

.stepNumber {
  grid-area: number;
  position: relative;

  @include tabletScreensUp {
    position: absolute;
    top: -85px;

    @include ltr {
      left: -35px;
    }

    @include rtl {
      right: -35px;
    }
  }
}

.stepIcon {
  grid-area: icon;
  display: flex;
  justify-content: center;
  position: relative;

  @include tabletScreensUp {
    margin-bottom: $spacing-lg;
  }
}
