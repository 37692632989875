@import 'styles/variables.scss';

.confirmDialogContainer {
  background: rgba(229, 238, 247, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  z-index: 200;
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirmDialog {
  min-width: 260px;
  max-width: 600px;
  margin: 10px;
  background: $white;
  padding: $spacing-lg;
  border: 1px solid $dimmed-blue-1;
  border-radius: $box-section-border-radius;
}
