@import 'styles/variables.scss';
@import 'styles/mixins.scss';

$section-height: 600px;
$section-height-mobile: 287px;

.root {
  min-height: $section-height-mobile;
  overflow: hidden;

  @include tabletScreensUp {
    height: calc($section-height + $navigation-bar-height);
    margin-bottom: calc($spacing-md * 4);
  }

  @media (min-width: 1200px) {
    margin-bottom: calc($spacing-md * 2);
  }
}

.container {
  height: 100%;
  display: flex;
  align-items: flex-end;
  padding: $spacing-md 0;
  position: relative;
  @include container;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    background: $color-secondary;
    width: 100vw;
    height: calc($section-height-mobile + $navigation-bar-height);
  }

  @include ltr {
    &::after {
      left: 47%;
      border-bottom-left-radius: 50px;
    }
  }

  @include rtl {
    &::after {
      right: 47%;
      border-bottom-right-radius: 50px;
    }
  }

  @include tabletScreensUp {
    align-items: center;

    @include rtl {
      &::after {
        right: 72%;
      }
    }

    @include ltr {
      &::after {
        left: 72%;
      }
    }

    &::after {
      height: 100%;
    }
  }

  @media (min-width: 1040px) {
    @include rtl {
      &::after {
        right: 63%;
      }
    }

    @include ltr {
      &::after {
        left: 63%;
      }
    }
  }

  @media (min-width: 1200px) {
    @include rtl {
      &::after {
        right: 60%;
      }
    }

    @include ltr {
      &::after {
        left: 60%;
      }
    }
  }
}

.booth {
  z-index: 90;
  position: absolute;
  top: 115px;

  width: 320px;
  height: 215px;
  transform-origin: center;

  @include rtl {
    right: 50%;
  }

  @include ltr {
    left: 50%;
  }

  @include tabletScreensUp {
    width: 370px;
    height: 248px;
    top: 140px;

    @include rtl {
      right: 77%;
    }

    @include ltr {
      left: 77%;
    }
  }

  @media (min-width: 830px) {
    @include rtl {
      right: 71%;
    }

    @include ltr {
      left: 71%;
    }
  }

  @media (min-width: 1200px) {
    width: 485px;
    height: 325px;
    top: 164px;

    @include rtl {
      right: 70.5%;
    }

    @include ltr {
      left: 70.5%;
    }
  }
}

.apps {
  z-index: 90;
  position: absolute;
  top: calc($navigation-bar-height + 45px);

  width: 216px;
  height: 198px;
  transform-origin: center;

  @include rtl {
    right: 50%;
  }

  @include ltr {
    left: 50%;
  }

  @include tabletScreensUp {
    width: 318px;
    height: 291px;
    top: calc($navigation-bar-height + 160px);

    @include rtl {
      right: 72%;
    }

    @include ltr {
      left: 72%;
    }
  }

  @media (min-width: 1040px) {
    width: 399px;
    height: 366px;
    top: calc($navigation-bar-height + 120px);

    @include rtl {
      right: 62%;
    }

    @include ltr {
      left: 62%;
    }
  }
}

.imgInner {
  width: 100%;
  height: 100%;
}

.boothsImg {
  background: url('../../assets/images/booth.svg');
  transform-origin: center;
  background-repeat: no-repeat;
  background-size: cover;

  @include ltr {
    transform: scale(-1, 1) translateX(50%);
  }

  @include rtl {
    transform: translateX(50%);
  }
}

.appsImg {
  background: url('../../assets/images/apps.svg');
  background-repeat: no-repeat;
  background-size: cover;
  transform-origin: center;

  @include rtl {
    transform: translateX(50%);
  }

  @include ltr {
    transform: translateX(-50%);
  }
}

.serviceNameContainer {
  position: absolute;
  top: 37%;
  z-index: 95;
  font-size: 24px;
  width: fit-content;
  display: none;

  @include ltr {
    font-weight: $heading-font-weight;
    left: 85vw;
    transform: rotate(18deg);
  }

  @include rtl {
    right: 88vw;
    transform: rotate(-18deg);
  }

  @media (min-width: 1040px) {
    display: block;
  }

  @media (min-width: 1200px) {
    top: 40%;

    @include ltr {
      left: 90%;
    }
    @include rtl {
      right: 90%;
    }
  }
}

.boothService {
  @media (min-width: 1040px) {
    display: none;
  }

  @media (min-width: 1200px) {
    display: block;
    top: 12%;

    @include ltr {
      left: 89%;
    }
    @include rtl {
      right: 89%;
    }
  }
}

.serviceName {
  @include ltr {
    font-weight: $thin-font-weight;
  }

  @include rtl {
    font-weight: $heading-font-weight;
  }
}

.serviceNamePath {
  position: absolute;
  top: 14px;
  transform-origin: center;

  @include ltr {
    transform: scale(-1, 1) rotate(14deg);
    left: -115px;
  }

  @include rtl {
    transform: rotate(14deg);
    right: -115px;
  }
}

.callToAction {
  width: 100%;
  margin-top: calc($section-height-mobile + $spacing-md);
  z-index: 90;

  @include tabletScreensUp {
    max-width: 390px;
    margin-top: calc($spacing-md * 3);
  }

  @media (min-width: 1200px) {
    margin-top: calc($spacing-md * 2);
  }

  button {
    max-width: 500px;
    display: block;
    margin: $spacing-md auto 0 auto;

    @include tabletScreensUp {
      display: inline-block;
      margin: 0;
    }
  }
}

.header {
  font-weight: $heading-font-weight;
  line-height: 38px;
  font-size: 26px;
  margin: 15px 0 20px 0;

  color: $text-color-primary;

  @include tabletScreensUp {
    line-height: 60px;
    font-size: 40px;
  }
}

.content {
  font-style: normal;
  font-weight: normal;
  margin-bottom: 30px;

  color: $text-color-secondary;
}

.tojjarLogo {
  position: absolute;
  top: calc($navigation-bar-height + $spacing-md);
  width: 45px;
  height: 43px;
  z-index: -1;

  @include tabletScreensUp {
    width: 64px;
    height: 47px;
    position: static;
  }

  @include rtl {
    right: 0;
  }

  @include ltr {
    left: 0;
  }
}
