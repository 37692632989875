@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.root {
  padding: 40px 20px 70px 20px;
  background: $color-secondary;
  z-index: 70;
  margin: 0 auto;
  max-width: $wide-section-max-width;

  @include tabletScreensUp {
    background: transparent;
  }
}

.heading {
  h3 {
    color: $dimmed-blue-2;

    @include tabletScreensUp {
      color: $text-color-secondary;
      margin-bottom: 45px;
    }
  }
}
