@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.field {
  margin-bottom: $spacing-md;
}

.fieldLabel {
  margin-bottom: $spacing-md;
  display: block;
  font-weight: $body-font-weight;
  color: $black;
  font-size: 14px;

  &::after {
    content: '*';
    color: $negative;
  }
}

.fieldError {
  margin-top: 8px;
  color: $negative;
  font-size: $body-font-size;
  font-weight: $body-font-weight;
}

.fieldOptions {
  display: flex;
  flex-direction: column;

  @include tabletScreensUp {
    flex-direction: row;
  }
}

.fieldOption {
  &:not(:last-child) {
    margin-bottom: 10px;
  }

  @include tabletScreensUp {
    margin-bottom: 0;
  }

  @include radioButton;
  [type='radio']:not(:checked) + label:before {
    border: 1px solid #ddd;
    background: #fff;
  }

  @include tabletScreensUp {
    &:first-child {
      @include ltr {
        margin-right: 20%;
      }
      @include rtl {
        margin-left: 20%;
      }
    }
  }
}

.uploadButton {
  padding: 6px 40px;
  max-width: 500px;
  margin: 0 auto;
  display: block;
  border-color: $color-primary-2;
  color: $color-primary-2;

  @include tabletScreensUp {
    margin: 0;
  }

  &:before {
    content: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAARCAYAAADtyJ2fAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAB2SURBVHgB5dGhEYAwDIXhPCLxMAFTYJig44CklnXYAMESbMAAWAqGO+CStleB4Xdt84lemJRM0fZVXjfLNk/SO2uIAAugOTFJmDV0nTXMPuTDHEIa5hgkYcSie7tzFu9LU3ZOGh7X4TGbUWJ/gN8HbW+hkv94AL15LgMEXyBsAAAAAElFTkSuQmCC');

    @include ltr {
      margin-right: 12px;
    }
    @include rtl {
      margin-left: 12px;
    }
  }
}

.fileList {
  list-style: none;
  margin: $spacing-md auto;
  padding: 0;
  max-width: 500px;

  @include tabletScreensUp {
    margin: $spacing-md 0;
  }
}

.file {
  color: #939598;
  display: flex;
  font-size: 14px;
  width: 100%;
  display: flex;

  @include tabletScreensUp {
    min-width: 300px;
    width: fit-content;
  }

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  &::before {
    content: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAARCAYAAADtyJ2fAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACySURBVHgB7dPPCcIwFAbw76Vpzo6QERxBR3CMgoiZQDeIKKW9OYo4SR1Br/7JM4qVUIM0Nw9+kEuSH+8F8sjaSsuctkzQiIRAx+vZTYwpDuG+EIrm/nQE9jS2wMNM0e5RIIRSMA9AhNm0+KhoK9+NQ+O1fuFxW1mgZwKsk2AXJ8EW54oW8tslUzzfQ+HepqwZqa2G+cOfgu8PsC7rpi+6AScpMywvjjU4Po/d+EHbK4HVHXy/PZn/AXpwAAAAAElFTkSuQmCC');

    @include ltr {
      margin-right: 10px;
    }
    @include rtl {
      margin-left: 10px;
    }
  }

  button {
    @include ltr {
      margin-left: auto;
    }
    @include rtl {
      margin-right: auto;
    }
  }
}

.hcDataContainer {
  margin-top: $spacing-md;
  margin-bottom: $spacing-md;
}

.logoContainer {
  margin-top: $spacing-md;
  margin-bottom: 50px;
}

.checkbox {
  max-width: 400px;
  margin: 0 auto;
  text-align: center;

  @include tabletScreensUp {
    text-align: initial;
  }

  &:not(:last-child) {
    margin-bottom: 40px;
  }

  [type='checkbox']:checked,
  [type='checkbox']:not(:checked) {
    display: none;
  }

  [type='checkbox'] + label span {
    position: relative;
    cursor: pointer;
    display: block;
    width: fit-content;
    text-align: initial;
    @include ltr {
      padding-left: 25px;
    }
    @include rtl {
      padding-right: 25px;
    }
  }

  [type='checkbox'] + label {
    @include ltr {
      padding-left: 25px;
    }
    @include rtl {
      padding-right: 25px;
    }
  }

  [type='checkbox'] + label span:before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 18px;
    height: 18px;
    border-radius: 100%;
    transition: all 0.2s linear;

    @include ltr {
      left: 0;
    }
    @include rtl {
      right: 0;
    }
  }

  [type='checkbox']:checked + label span:before {
    border: 4px solid hsl(209, 85%, 47%);
    background: $white;
  }
  [type='checkbox']:not(:checked) + label span:before {
    border: 1px solid hsl(210, 3%, 75%);
    background: transparent;
  }
}

.optionError {
  @include ltr {
    padding-left: 25px;
  }
  @include rtl {
    padding-right: 25px;
  }
}
