@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.container {
  max-width: $box-section-max-width;
  margin: 0 auto;
  padding: $box-section-padding;
  position: relative;
  background: $color-secondary-2;
  color: $white;
  overflow: hidden;
  z-index: 70;

  @include tabletScreensUp {
    border-radius: $box-section-border-radius;
    margin-top: 120px;
    padding: $box-section-padding-desktop;
  }

  @include tabletScreensUp {
    &:after {
      content: '';
      width: $box-section-max-width;
      height: 70px;
      background: rgba(255, 255, 255, 0.05);
      display: block;
      position: absolute;
      right: -70%;
      top: 400px;
      transform: skew(-50deg, 50deg);
      z-index: -1;
    }
  }
}

.form {
  margin-bottom: 30px;
}

.heading {
  font-size: 26px;
  font-weight: $body-font-weight;
  text-align: center;
}

.options {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;

  @include tabletScreensUp {
    flex-direction: row;
    margin-bottom: $spacing-lg;
  }
}

.option {
  &:not(:last-child) {
    margin-bottom: 10px;
  }

  @include tabletScreensUp {
    margin-bottom: 0;
  }

  @include radioButton;

  &:first-child {
    @include ltr {
      margin-right: $spacing-md;
    }
    @include rtl {
      margin-left: $spacing-md;
    }
  }
}

.question {
  @include registerInput;
  background: $white;
  border-radius: 4px;
  border: 1px solid transparent;
  height: 100px;
  font-family: inherit;

  &:focus {
    box-shadow: 0 0 0 1px $gray-85;
  }
}

.questionWithError {
  border-color: $negative;

  &:focus {
    box-shadow: none;
  }
}

.emailField,
.phoneField {
  background: $white;
}

.submitButton {
  margin: 30px auto 0 auto;
  display: block;
  max-width: 500px;

  @include tabletScreensUp {
    margin: 40px auto 0 auto;
  }
}

.fieldWithError {
  margin-top: 4px;
  color: $negative;
  font-size: $body-font-size;
  font-weight: $body-font-weight;
}

.customLabel {
  margin-bottom: 8px;
  display: block;
  font-weight: $body-font-weight;
  color: $white;
  font-size: $body-font-size;

  &::after {
    content: '*';
    color: $negative;
  }

  @include tabletScreensUp {
    display: none;
  }
}

.separator {
  @include tabletScreensUp {
    height: 30px;
  }
}
