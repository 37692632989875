@import 'styles/variables.scss';
@import 'styles/mixins.scss';

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.primary {
  background: $color-primary-2;
  border-radius: 50px;
  color: $white;
}

.primaryLight {
  background: $color-primary-1;
  border-radius: 50px;
  color: $white;
}

.big {
  padding: 17px 64px;
  font-size: 15px;
  text-transform: uppercase;
  width: 100%;

  @include tabletScreensUp {
    width: auto;
  }
}

.medium {
  padding: 5px 50px;
  font-size: 15px;
  text-transform: uppercase;
  width: 100%;

  @include tabletScreensUp {
    width: auto;
  }
}

.outlined {
  border: 1px solid $dimmed-blue-2;
  border-radius: 50px;
  color: $dimmed-blue-3;
}

.loading {
  border: 2px solid transparent;
  border-top: 2px solid $white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: block;
  animation: spin 0.8s linear infinite;
  margin: 0 auto;
}
